<template>
    <section>
            <search-pagination-documents v-bind:items="results.sentencesByDocumentWithMeta" textHandle="text">
            </search-pagination-documents>
    </section>
</template>

<script>
    import SearchResultCard from '@/components/partials/SearchResultCard.vue';
    import SearchPaginationDocuments from "@/components/partials/SearchPaginationDocuments.vue";

    export default {
        name: "SearchResultDocuments",
        props: {
            results: Object,
        },
        components: {
            SearchPaginationDocuments,
            SearchResultCard
        }
    }
</script>

<style scoped>
    ul {
        padding-left: 0;
    }
</style>
