<template>
    <div
            v-bind:class="classes"
            @mouseleave="handleClose"
            @mouseenter="cancelClose">
        <a class="label" @click="handleOpen">
            {{ l }} <span ref="chevron" v-bind:style="{ width: chevronWidth }"></span>
        </a>
        <ul class="options" v-if="open">
            <li v-for="(text,option) in options" :key="option">
                <a @click="handleOption(option)" :class="isActive(option) ? 'active' : ''">
                    {{ text === 'springer' ? `${text}-openaccess` : text }}
                </a>
            </li>
        </ul>
        <select v-model="s" :id="id" :multiple="multiple">
            <option v-for="(text,option) in options" v-bind:value="option" :key="option">
                {{ text === 'springer' ? `${text}-openaccess` : text }}
            </option>
        </select>
    </div>
</template>

<script>
    export default {
        name: "IndexSelectBox",
        data: function() {
            let sv = this.multiple ? ["springer"] : null;
            return {
                s: sv,
                id: '',
                o: false,
                stateReadable: 'closed',
                closeTimer: null,
                isMounted: false,
            }
        },
        props: {
            selected: Array,
            options: Object,
            label: String,
            expand: Boolean,
            multiple: Boolean,
            required: Boolean
        },
        computed: {
            classes() {
                return {
                    'index-select-box': true,
                    'is-open': this.o,
                    'is-touch': this.isTouch,
                    'is-expandible': this.expand,
                    'is-multiple': this.multiple,
                    'is-required': this.required
                };
            },
            isTouch() {
                return this.$root.isTouch;
            },
            l() {
                return this.label;
            },
            open: {
                get() {
                    if (this.expand && !this.$root.isMobile) return true;
                    return this.o;
                },
                set(v) {
                    this.o = v;
                    this.stateReadable = this.o ? 'open' : 'closed';
                }
            },
            // chevronWidth() {
            //     if (this.isMounted && this.$root.isResized) {
            //         let width = this.$refs.chevron.clientHeight;
            //         return `${width}px`;
            //     }
            //     return '30px';
            // }
        },
        methods: {
            handleOpen() {
                // TODO: what should happen with touch?
                if (this.$root.isTouch) {
                    this.open = !this.open;
                    // this.open = false;
                } else {
                    this.open = !this.open;
                }
            },
            handleClose() {
                this.closeTimer = setTimeout(() => {
                    this.open = false
                }, 500);
            },
            cancelClose: function() {
                clearTimeout(this.closeTimer);
            },
            handleOption(v) {
                if (!this.multiple)
                    this.open = false;

                if (this.multiple) {
                    let idx = this.s.indexOf(v);
                    if (idx > -1) {
                        this.s.splice(idx, 1);
                    } else {
                        this.s.push(v);
                    }
                } else {
                    this.s = v;
                }
            },
            isActive(v) {
                if (this.multiple) {
                    return this.s.indexOf(v) > -1;
                } else {
                    return v === this.s;
                }
            }
        },
        watch: {
            s() {
                this.$emit('changed', this.s);
            },
            selected() {
                this.s = this.selected;
            }
        },
        created() {
            this.s = this.selected;
            this.id = `index-select-box-${this._uid}`;
        },
        mounted() {
            this.isMounted = true;
        }
    }
</script>

<style scoped lang="scss">
    @import "@/scss/custom-bootstrap";
@import "~bootstrap/scss/bootstrap";
    .index-select-box {
        position: relative;
        display: inline-block;
        margin: 0;
        font-size: 20px;
        font-family: Signika, sans-serif;
        color: $white;
        user-select: none;
        background-color: $primary;

        @include media-breakpoint-up(lg) {
            width: 160px;
            height: 80px;
            line-height: 80px;

            &.is-open {
                background-color: $white;
                color: $primary !important;
            }
        }

        select {
            display: none;
        }

        &.is-touch select {
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            opacity: 0;
        }

        a {
            cursor: pointer;
            display: block;
        }

        .label {
            // padding-right: 30px;
            padding: 8px 30px 6px 10px;;
            box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
            height: inherit;
            overflow: hidden;
            text-overflow: ellipsis;

            @include media-breakpoint-up(lg) {
                position: absolute;
                width: 100%;
                z-index: 801;
                padding: 0 map_get($spacers, 3);
            }

            span {
                display: block;
                line-height: 1;
                position: absolute;
                top: 6px;
                bottom: 6px;
                right: 6px;
                width: 20px;

                @include media-breakpoint-up(lg) {
                    width: 60px;
                }

                &:before {
                    display: block;
                    width: 8px;
                    height: 8px;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    margin: -4px 0 0 -4px;
                    transform-origin: center;
                    font-size: 8px;
                    content: '\e900';
                    transition: transform 0.25s;
                    text-align: center;
                    font-family: 'as-icons' !important;

                    @include media-breakpoint-up(lg) {
                        width: 16px;
                        height: 16px;
                        font-size: 16px;
                        margin: -8px 0 0 -8px;
                    }
                }
            }
        }

        .label span {
            @include media-breakpoint-up(lg) {
                border-left: 2px solid rgba($white, .5);
            }
        }

        &.is-open .label span {
            border-left-color: rgba($gray, .5);
        }

        &.is-open .label span:before {
            transform: rotate(-180deg);
        }

        .options {
            list-style-type: none;
            position: absolute;
            z-index: 800;
            top: 100%;
            background-color: $dark-green;
            left: 0;
            @extend .p-3;

            li {
                line-height: 1.8em;

                a.active {
                    color: $yellow !important;
                }
            }

            @include media-breakpoint-up(lg) {
                top: 0px;
                right: 0;
                left: auto;
                min-width: 100%;
                background-color: inherit;
                box-shadow: 0 0 10px rgba(0, 0, 0, .3);
                padding-top: 80px !important;
                a {
                    color: $gray !important;
                }
            }
        }

        &.is-multiple {
            .options {

                li.toggles {
                    text-align: right;

                    a {
                        display: inline-block;
                    }
                }

                a {
                    white-space: nowrap;

                    &:before {
                        font-family: 'as-icons' !important;
                        content: '\e904';
                        display: inline-block;
                        @extend .border;
                        width: 24px;
                        height: 24px;
                        text-align: center;
                        line-height: 24px;
                        border-radius: 4px;
                        font-size: 8px;
                        color: $white;
                        top: -.3em;
                        position: relative;
                    }

                    &.active {
                        color: $gray !important;

                        &:before {
                            background-color: $gray;
                            box-shadow: inset 0 0 5px 0 rgba(0, 0, 0, .1);
                        }
                    }
                }
            }
        }

        &.is-expandible {
            @include media-breakpoint-up(lg) {
                background: transparent;
                width: auto;
                padding: 0 !important;
                margin: 0 !important;

                .label {
                    display: none;
                }
                .options {
                    display: block;
                    position: static;
                    background-color: transparent;
                    padding: 0 !important;
                    margin: 0;
                    box-shadow: none;

                    li {
                        display: inline-block;
                        margin-right: map-get($spacers, 3);

                        &:last-child {
                            margin-right: 0;
                        }

                        a {
                            height: 80px;
                            line-height: 80px;
                            text-transform: uppercase;
                            font-weight: 300;

                            color: $white !important;

                            &.active {
                                border-bottom: 4px solid $white;
                                font-weight: 500;
                            }

                            &:hover {
                                color: $yellow !important;
                            }
                        }
                    }
                }
            }
        }
    }
</style>
