<template>
    <section>
        <!--<div class="row" style="justify-content: center;margin-bottom: 30px">
            <simple-graph style="height:300px;width: 90%" :chartData="chartData" :options="chartOptions">
            </simple-graph>
        </div>-->
        <div class="row">
            <search-pagination-list v-bind:items="results.sentencesOrdered" textHandle="text">
            </search-pagination-list>
        </div>
    </section>
</template>

<script>
    import SearchPaginationList from '@/components/partials/SearchPaginationList.vue';
    import SimpleGraph from "@/components/modules/SimpleGraph.vue";
    import moment from 'moment';

    export default {
        name: "SearchResultsProCon",
        props: {
            results: Object,
        },
        created() { // Set chart data and options at initial load of page
            this.computeChartData(this.results)
        },
        data: function () {
            return {
                // Just the dummy-format of the chart data and chart options;
                // the actual data as well as the Min/Max value of the y-axis and the labels of the tooltips have to
                // be calculated dynamically
                chartData: {
                    labels: [],
                    datasets: [
                        {
                            backgroundColor: "#A1B870",
                            maxBarThickness: 5,
                            data: []
                        },
                        {
                            backgroundColor: "#D87256",
                            maxBarThickness: 5,
                            data: []
                        },
                        {   
                            type: 'scatter',
                            label: 'Trend Line',
                            data: []
                        }
                    ]
                },
                chartOptions: {
                    scales: {
                        xAxes: [{
                            stacked: true
                        }],
                        yAxes: [{
                            ticks: {
                                display: false,
                                max: 50,
                                min: -50
                            },
                            stacked: true
                        }]
                    },
                    legend: {
                        display: false
                    },
                    maintainAspectRatio: false,
                    responsive: true,
                    tooltips: {
                        callbacks: {
                            label: function (tooltipItem, data) {
                                let label = data.datasets[tooltipItem.datasetIndex].label || '';

                                if (label) {
                                    label += ': ';
                                }
                                label += Math.abs(tooltipItem.yLabel);
                                return label;
                            }
                        }
                    }
                }
            }
        },
        watch: { // Reload chart data and options when sentences have changed (e.g. for new query)
            results: function (newVal, oldVal) {
                this.computeChartData(newVal)
            }
        },
        components: {
            SimpleGraph,
            SearchPaginationList
        },
        methods: {
            computeChartData: function (res) {
                // This function calculates the data for the chart as well as the options.
                // The dates from the results reach from "today" up to 90 days before today.
                // There should be one bar for each day in these interval, so we create one map from days to counts of
                // pro/contra arguments to get the number of arguments per day.
                // The input "res" is the results object given from the API; For the chart the relevant and exemplary
                // structure of that object is
                // res: {
                //      sentences: [ # There is only one element in the outer list
                //          [ # The first item of res.sentences contains all sentences
                //              {
                //                  date: <date of sentence 1>,
                //                  stanceLabel: <pro/contra>
                //              },
                //              <other-sentences>
                //          ]
                //      ]
                // }
                try {
                    // Start on the day 90 days before today
                    let ctr = moment().subtract(90, 'days')

                    let argCount = {}

                    // Iterate over the 90 days interval and initialize our counter map by setting the pro/contra counter
                    // to 0 for each day in the interval
                    for(let i = 0; i <= 90; i++) {
                        argCount[ctr.format("YYYY-MM-DD")] = {"pro": 0, "contra": 0}
                        ctr = ctr.add(1, 'days')
                    }

                    // Iterate over each returned sentence for the query and increment the counter given the date as well as
                    // the label (pro/contra) of the sentence
                    res["sentences"][0].forEach(function (elem) {
                        let date = moment(elem["date"]).format("YYYY-MM-DD")
                        argCount[date][elem["stanceLabel"]]++
                    })

                    // Prepare labels for the chart, i.e. the keys from our counter map
                    let labels = Object.keys(argCount).sort()
                    let pros = []
                    let cons = []
                    let maxCtr = 0
                    // Prepare the two datasets (pro/contra) for the chart by splitting the counter map elements into
                    // pro and contra and get the max number of arguments for all days
                    labels.forEach(elem => {
                        let proCnt = argCount[elem]["pro"]
                        let conCnt = argCount[elem]["contra"]
                        pros.push(proCnt)
                        cons.push(conCnt)
                        maxCtr = Math.max(maxCtr, proCnt, conCnt)
                    })
                    
                    // Computes start and end point of trend line
                    let line_coords = []

                    // Average of first and second half of data
                    let first_pro = pros.slice(0, 45)
                    let first_con = cons.slice(0, 45)
                    let second_pro = pros.slice(45)
                    let second_con = cons.slice(45)

                    let first = []
                    let second = []
                    for(var i = 0;i<45;i++) {
                        if (!(first_pro[i]==0 && first_con[i]==0))
                            first.push(first_pro[i] - first_con[i])
                        if (!(second_pro[i]==0 && second_con[i]==0))
                            second.push(second_pro[i] - second_con[i])
                    }
                    
                    // Make sure arrays contain at least one element
                    if (first.length == 0)
                        first.push(0)
                    if (second.length == 0)
                        second.push(0)
                    
                    line_coords.push({x:moment(labels[0]).format("DD-MM-YYYY"), y:first.reduce((a,b) => a + b, 0) / first.length})
                    line_coords.push({x:moment(labels[labels.length-1]).format("DD-MM-YYYY"), y:second.reduce((a,b) => a + b, 0) / second.length})
                    
                    // Ceil the maximum number of arguments for all days to the next multiple of 10
                    // This will be the Min/Max value of the y-axis
                    let nearestTen = 10 * Math.ceil(maxCtr / 10)

                    this.chartOptions = {
                        scales: {
                            xAxes: [{
                                stacked: true
                            }],
                            yAxes: [{
                                ticks: {
                                    display: false,
                                    max: nearestTen,
                                    min: -nearestTen
                                },
                                stacked: true
                            }]
                        },
                        legend: {
                            display: false
                        },
                        maintainAspectRatio: false,
                        responsive: true,
                        tooltips: {
                            callbacks: {
                                // Review the labels of the tooltip, because for the contra bars, the total number of
                                // arguments would be smaller than 0, but we want the absolute values
                                label: function (tooltipItem, data) {
                                    let label = data.datasets[tooltipItem.datasetIndex].label || '';
                                    if (label) {
                                        label += ': ';
                                    }
                                    // Trendline -> Round values
                                    if (tooltipItem.datasetIndex == 2) {
                                        label += Math.round(tooltipItem.yLabel*100)/100;
                                        return label;
                                    }
                                    // Pro/ Con -> Absolute values
                                    else {
                                        label += Math.abs(tooltipItem.yLabel);
                                        return label;
                                    }
                                },
                                title: function (tooltipItem, data) {
                                    // Set correct title for second point of trendline
                                    if (tooltipItem[0].datasetIndex == 2 && tooltipItem[0].index == 1) {
                                        return data.labels[data.labels.length-1];
                                    }
                                    return tooltipItem[0].xLabel;
                                }
                            }
                        }
                    }

                    this.chartData = {
                        // Review the labels for the x-axis from format YYYY-MM-DD to DD-MM-YYYY
                        labels: labels.map(elem => moment(elem).format("DD-MM-YYYY")),
                        datasets: [
                            {
                                backgroundColor: "#A1B870",
                                maxBarThickness: 5,
                                data: pros
                            },
                            {
                                backgroundColor: "#D87256",
                                maxBarThickness: 5,
                                data: cons.map(elem => 0 - elem)
                            },
                            // data contains Start and end point of trendline
                            {
                                type: 'scatter',
                                borderColor: "#ffca19",
                                fill: false,
                                tension: 0,
                                showLine: true,
                                data: line_coords
                            }
                        ]
                    }
                }
                catch (err) {
                    console.log("Some error occured:")
                    console.log(err)
                }
            }
        }
    }
</script>

<style scoped>

</style>
