<template>
    <search-pagination-list v-bind:items="results.sentences" textHandle="text">
    </search-pagination-list>
</template>

<script>
    import SearchPaginationList from '@/components/partials/SearchPaginationList.vue';

    export default {
        name: "SearchResultsList",
        props: {
            results: Object,
        },
        components: {
            SearchPaginationList
        }
    }
</script>

<style scoped>

</style>
