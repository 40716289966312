import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersist from 'vuex-persist';

import searchQuery from './modules/searchQuery';

Vue.use(Vuex);

const vuexLocalStorage = new VuexPersist({
    strictMode: true,
    key: 'ArgumentSearch',
    storage: window.localStorage,
});

const debug = process.env.NODE_ENV !== 'production';

export default new Vuex.Store({
    modules: {
        searchQuery,
    },
    mutations: {
        RESTORE_MUTATION: vuexLocalStorage.RESTORE_MUTATION
    },
    plugins: [vuexLocalStorage.plugin],
    strict: debug,
});
