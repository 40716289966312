<template>
  <v-timeline>
    <v-timeline-item v-for="(d, index) in data" :key="index" :class="index%2 ? 'text-right': ''" :color="index%2 ? '#638e6e': '#ffca19'">
        {{d.text}}
    </v-timeline-item>
  </v-timeline>
</template>

<script>
export default {
  name: "Timeline",
  props: ["data"]
};
</script>
<style scoped>
.theme--light.v-timeline:before{
    margin: 0 50%;
}
</style>