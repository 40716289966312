import Vue from 'vue'
import moment from "moment";

moment.locale(window.navigator.userLanguage || window.navigator.language);

Vue.filter('formatDate', function(value) {
    if (value) {
        return moment(value).format('MMM D, Y');
    }
});
