export const subjects = {
    fb01: "Law and Economics",
    fb02: "History and Social Sciences",
    fb03: "Human Sciences",
    fb04: "Mathematics",
    fb05: "Physics",
    fb07: "Chemistry",
    fb10: "Biology",
    fb11m: "Material Science",
    fb11g: "Earth Sciences",
    fb13: "Civil and Environmental Engineering",
    fb15: "Architecture",
    fb16: "Mechanical Engineering",
    fb18: "Electrical Engineering and Information Technology",
    fb20: "Computer Science",
    fb00: "No Subject assigned"
  }