import axios from 'axios';
import Vue from 'vue';
import VueApiService from 'vue-api-service';

import MockData from './mocks/searchResult';

const client = axios.create();

const endpoints = {
    searchResults: {
        method: 'POST',
        //url: 'https://argumentsearchproxy.dev.schumacher-aktuell.de/:language/search',
        //url: 'https://api.argumentsearch.com/:language/search',
        url: '/search',
    },
    // documentTitles: {
    //     method: 'GET',
    //     //url: 'https://argumentsearchproxy.dev.schumacher-aktuell.de/titles.php',
    //     url: '/titles',
    // },
};



const mocks = {
    searchResults: (request) => {
        return Promise.resolve({
            data: MockData
        })
    },
};

let options = {
    client,
    endpoints,
};

//if (process.env.NODE_ENV !== 'production') {
//    options = Object.assign(options, {mocks})
//}

Vue.use(VueApiService, options);
