<template>
    <main>
        <app-header></app-header>
        <router-view></router-view>
        <app-footer></app-footer>
    </main>
</template>

<script>

    export default {
        name: "App",
    }
</script>

<style scoped>

</style>