<template>
  <b-form
    inline
    action="/search"
    class="d-flex align-items-center justify-content-center"
    v-on:submit.prevent="search"
  >
    <b-form-group>
      <b-input-group class="shadow">
        <!-- <b-form-select class="bg-primary text-light" :value="language" required name="language"
                               v-on:change="updateLanguage">
                    <option value="de">DE</option>
                    <option value="en">EN</option>
                </b-form-select> -->
        <index-select-box
          class="ml-lg-auto"
          label="Subjects"
          name="subjects"
          :selected="subjectsCopy"
          required
          multiple
          :options="subjectOptions"
          @changed="updateSubjects"
        >
        </index-select-box>
        <b-form-input
          class="text-primary"
          type="text"
          required
          placeholder="Search for arguments..."
          name="query"
          :value="topic || query"
        >
        </b-form-input>
        <span class="input-group-btn">
          <b-button
            v-if="loading"
            type="button"
            variant="white"
            class="text-info"
          >
            <i class="icon-spinner"></i>
          </b-button>
          <b-button v-else type="submit" variant="white" class="text-info">
            <i class="icon-search"></i>
          </b-button>
        </span>
      </b-input-group>
    </b-form-group>
  </b-form>
</template>

<script>
    import {mapState} from 'vuex';
    import IndexSelectBox from '@/components/partials/IndexSelectBox.vue';
    import {subjects as apiSubjects} from '@/api/subjects.js';
    import _cloneDeep from 'lodash/cloneDeep';

    export default {
        name: 'SearchForm',
        props: ["loading", "query"],
        data() {
            return {
                loading: this.loading,
                subjectOptions: Object.keys(apiSubjects)
                  .filter(key => key !== 'fb00')
                  .reduce((result, key) => {
                    result[key] = apiSubjects[key];
                    return result;
                  }, {}),
                subjectsCopy: _cloneDeep(this.$store.state.searchQuery.searchSubjects) || []
            };
        },
        computed: {
            subjects:{
              get(){
                return this.$store.state.searchQuery.searchSubjects || []
              },
              set(value){
                this.$store.commit("searchQuery/updateSearchSubjects", value);
              }
            },
            topic() {
                return this.$route.query.query;
            },
        },
        methods: {
            updateSubjects(v) {
                this.subjectsCopy = v;
                this.subjects = _cloneDeep(this.subjectsCopy);
            },
            search(e) {
                const formData = new FormData(e.target);
                const aquery = formData.get('query');
                let self = this
                let subjects_no_change = this.$route.query.subjects 
                            ? (this.$route.query.subjects.length == this.subjects.length) && this.$route.query.subjects.every(function(element, i) {
                                return element === self.subjects[i];
                            })
                            : false;

                if(this.$route.query.query === aquery && subjects_no_change) return
                this.loading = true;

                this.$router.push({
                    name: 'results',
                    query: {
                        query: aquery,
                        subjects: this.subjects
                    }
                });
                
            },
        },
        components: {
            IndexSelectBox
        }
    };
</script>

<style lang="scss" scoped>
@import "@/scss/custom-bootstrap";
@import "~bootstrap/scss/bootstrap";

$searchbarHeight: 48px;
$searchbarHeightLG: 80px;

//SEARCHBAR HOME
.form-group {
  @extend .my-0;
}

#search-form-large {
  .input-group > select[name="language"] {
    height: $searchbarHeight;
    width: $searchbarHeight * 1.2;
    padding: 0 0 0 12px;
    margin: 0;
    flex: 0 0 auto;
    color: $white !important;
    font-family: $font-family-sans-serif;
    font-weight: 400;
    border: 0 !important;
    cursor: pointer;
    font-size: $font-size-sm;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    @include media-breakpoint-up(lg) {
      height: $searchbarHeightLG;
      width: 83px;
      font-size: 20px;
    }

    &:hover {
      color: $yellow !important;
    }
  }

  .custom-select {
    background-image: url("data:image/svg+xml;charset=utf8,<svg version='1.1' id='Ebene_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='38px' height='38px' viewBox='0 0 38 38' enable-background='new 0 0 38 38' xml:space='preserve'><path id='path-1_1_' fill='%23fff' d='M18.667,14.896L4.552,0.781c-1.042-1.042-2.73-1.042-3.771,0c-1.042,1.041-1.042,2.729,0,3.771l16,16c1.041,1.041,2.729,1.041,3.771,0l16-16c1.041-1.042,1.041-2.729,0-3.771c-1.042-1.042-2.729-1.042-3.771,0L18.667,14.896z'/></svg>");
    background-position: 75% 55%;
    @include media-breakpoint-up(lg) {
      background-size: 16px;
      background-position: 75% 55%;
    }

    &:hover {
      background-image: url("data:image/svg+xml;charset=utf8,<svg version='1.1' id='Ebene_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='38px' height='38px' viewBox='0 0 38 38' enable-background='new 0 0 38 38' xml:space='preserve'><path id='path-1_1_' fill='%23ffca19' d='M18.667,14.896L4.552,0.781c-1.042-1.042-2.73-1.042-3.771,0c-1.042,1.041-1.042,2.729,0,3.771l16,16c1.041,1.041,2.729,1.041,3.771,0l16-16c1.041-1.042,1.041-2.729,0-3.771c-1.042-1.042-2.729-1.042-3.771,0L18.667,14.896z'/></svg>");
    }
  }

  .input-group > input[name="query"] {
    border: none !important;
    height: $searchbarHeight;
    padding-left: 25px;
    padding-right: 5px;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    @include media-breakpoint-up(lg) {
      font-weight: 700;
      font-size: $h5-font-size;
      height: $searchbarHeightLG;
      //width: 50vw;
      width: 650px;
    }
  }

  .input-group > .index-select-box[name="index"] {
    // line-height: 80px;
    @include media-breakpoint-up(lg) {
      // line-height: 80px;
    }
  }

  .input-group {
    @extend .my-4;
    @extend .my-lg-8;
    height: $searchbarHeight;
    @include media-breakpoint-up(lg) {
      height: $searchbarHeightLG;
    }
  }

  .shadow {
    webkit-box-shadow: 0 1.5px 3px 0 rgba(0, 0, 0, 0.2) !important;
    -moz-box-shadow: 0 1.5px 3px 0 rgba(0, 0, 0, 0.2) !important;
    box-shadow: 0 1.5px 3px 0 rgba(0, 0, 0, 0.2) !important;
  }

  .btn {
    height: $searchbarHeight;
    background-color: #fff;
    margin: 0;
    padding: 0 20px;
    @include media-breakpoint-up(lg) {
      height: $searchbarHeightLG;
      font-size: 24px;
    }

    i {
      color: $yellow;
    }

    &:hover > i {
      color: $primary;
    }
  }
}

// SEARCHBAR SEARCH RESULTS

.app-tabs-nav {
  form {
    @extend .d-flex;
    @extend .align-items-center;

    width: 100%;
    height: 60px;
    padding-top: 8px;
    pointer-events: none;

    @include media-breakpoint-up(lg) {
      padding-top: 0;
      position: absolute;
      top: -120px;
      height: 120px;
      width: 100%;
    }

    fieldset {
      width: 100%;
      @include media-breakpoint-up(sm) {
        width: auto;
      }
    }
  }

  .form-group {
    @extend .align-middle;

    & > div {
    }
  }

  .input-group {
    pointer-events: all;

    & > select[name="language"] {
      height: $searchbarHeight;
      width: 56px;
      max-width: 56px;
      font-family: $font-family-sans-serif;
      font-size: $font-size-base;
      font-weight: 600;
      padding: 0 0 0 5px;
      margin: 0;
      border: 0 !important;
      transition: none;

      &:hover {
        color: $yellow !important;
      }

      @include media-breakpoint-up(lg) {
        width: 60px;
        max-width: 60px;
        height: 40px;

        flex: 0 0 auto;
        color: $dark-green !important;
        background-color: $white !important;
        font-family: $font-family-sans-serif;
        font-size: $font-size-base;
        font-weight: 600;
        border: 0 !important;
        cursor: pointer;
        -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
        -moz-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
      }
    }

    & > .index-select-box {
      height: $searchbarHeight;
      font-family: $font-family-sans-serif;
      font-size: $font-size-base;
      font-weight: 600;
      padding: 0 0 0 5px;
      border: 0 !important;
      transition: none;
      line-height: 30px;

      &:hover {
        color: $yellow !important;
      }

      @include media-breakpoint-up(lg) {
        width: 155px;
        max-width: 155px;
        height: 40px;

        flex: 0 0 auto;
        // background-color: $dark-green !important;
        // color: $white !important;
        font-family: $font-family-sans-serif;
        font-size: $font-size-base;
        font-weight: 600;
        border: 0 !important;
        cursor: pointer;
        -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
        -moz-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
        line-height: 40px;
      }
    }
  }

  .custom-select {
    background-image: url("data:image/svg+xml;charset=utf8,<svg version='1.1' id='Ebene_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='38px' height='38px' viewBox='0 0 38 38' enable-background='new 0 0 38 38' xml:space='preserve'><path id='path-1_1_' fill='%23fff' d='M18.667,14.896L4.552,0.781c-1.042-1.042-2.73-1.042-3.771,0c-1.042,1.041-1.042,2.729,0,3.771l16,16c1.041,1.041,2.729,1.041,3.771,0l16-16c1.041-1.042,1.041-2.729,0-3.771c-1.042-1.042-2.729-1.042-3.771,0L18.667,14.896z'/></svg>");
    background-size: 12px;
    background-position: 75% 57%;

    @include media-breakpoint-up(lg) {
      background-image: url("data:image/svg+xml;charset=utf8,<svg version='1.1' id='Ebene_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='38px' height='38px' viewBox='0 0 38 38' enable-background='new 0 0 38 38' xml:space='preserve'><path id='path-1_1_' fill='%23225077' d='M18.667,14.896L4.552,0.781c-1.042-1.042-2.73-1.042-3.771,0c-1.042,1.041-1.042,2.729,0,3.771l16,16c1.041,1.041,2.729,1.041,3.771,0l16-16c1.041-1.042,1.041-2.729,0-3.771c-1.042-1.042-2.729-1.042-3.771,0L18.667,14.896z'/></svg>");
      background-size: 14px;
      background-position: 32px 17px;

      &:hover {
        background-image: url("data:image/svg+xml;charset=utf8,<svg version='1.1' id='Ebene_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='38px' height='38px' viewBox='0 0 38 38' enable-background='new 0 0 38 38' xml:space='preserve'><path id='path-1_1_' fill='%23ffca19' d='M18.667,14.896L4.552,0.781c-1.042-1.042-2.73-1.042-3.771,0c-1.042,1.041-1.042,2.729,0,3.771l16,16c1.041,1.041,2.729,1.041,3.771,0l16-16c1.041-1.042,1.041-2.729,0-3.771c-1.042-1.042-2.729-1.042-3.771,0L18.667,14.896z'/></svg>");
      }
    }
  }

  .input-group > input[name="query"] {
    border: none !important;
    width: 1%;
    height: $searchbarHeight;
    padding-left: 16px;
    padding-right: 5px;

    font-weight: 400;
    -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    -moz-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);

    @include media-breakpoint-up(md) {
      width: 500px;
    }

    @include media-breakpoint-up(lg) {
      font-size: 20px;
      height: 40px;
      width: 405px;
    }

    @include media-breakpoint-up(xl) {
      width: 505px;
    }
  }

  .input-group {
    margin-top: 0;
    height: $searchbarHeight;
    pointer-events: all;
    width: 100%;

    @include media-breakpoint-up(lg) {
      height: 40px;
    }
  }

  .shadow {
    -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0) !important;
    -moz-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0) !important;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0) !important;

    @include media-breakpoint-up(lg) {
      -webkit-box-shadow: 0px 1px 0px 0px rgba(210, 210, 210, 1) !important;
      -moz-box-shadow: 0px 1px 0px 0px rgba(210, 210, 210, 1) !important;
      box-shadow: 0px 1px 0px 0px rgba(210, 210, 210, 1) !important;
    }
  }

  .btn {
    height: $searchbarHeight;
    font-size: 20px;
    background-color: #fff;
    margin: 0;
    padding: 0;

    @include media-breakpoint-up(lg) {
      height: 40px;
    }

    i {
      margin-right: 12px;
      margin-left: 7px;

      @include media-breakpoint-up(lg) {
        color: $primary;
        margin-right: 10px;
        margin-left: 5px;
      }
    }

    &:hover > i {
      color: $yellow;
    }
  }
}
</style>
