import Vue from 'vue';
import Router from 'vue-router';
import NProgress from 'nprogress';
import Store from '@/store';

import Home from '@/components/Home.vue'

import SearchResult from '@/components/SearchResult.vue'
import SearchResultProCon from '@/components/search/SearchResultProCon.vue'
import SearchResultList from '@/components/search/SearchResultList.vue'
import SearchResultWeights from '@/components/search/SearchResultWeights.vue'
import SearchResultDocuments from '@/components/search/SearchResultDocuments.vue'

import TermsOfUse from '@/components/terms_etc/TermsOfUse.vue'
import Privacy from '@/components/terms_etc/Privacy.vue'
import Legal from '@/components/terms_etc/Legal.vue'
import Background from '@/components/terms_etc/Background.vue'

Vue.use(Router);

const router = new Router({
    mode: 'history',
    base: __dirname,
    scrollBehavior() {
        return {x: 0, y: 0};
    },
    routes: [
        {
            name: 'home',
            path: '/',
            component: Home,
        },
        // {
        //     path: '/terms',
        //     component: TermsOfUse,
        // },
        {
            path: '/background',
            component: Background,
        },
        {
            path: '/privacy',
            component: Privacy,
        },
        {
            path: '/legal',
            component: Legal,
        },
        {
            path: '/search',
            component: SearchResult,
            meta: {c: true},
            children: [
                {
                    name: 'results',
                    path: '',
                    component: SearchResultProCon,
                },
                {
                    name: 'results_list',
                    path: 'list',
                    component: SearchResultList,
                },
                {
                    name: 'results_weights',
                    path: 'weights',
                    component: SearchResultWeights,
                },
                {
                    name: 'results_documents',
                    path: 'documents',
                    component: SearchResultDocuments,
                },
            ]
        },
    ],
});


router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresSearchQuery)) {
        if (to.query.query === undefined || to.query.query === '') {
            next({name: 'home'});
            return;
        }

        if (to.query.language === undefined || to.query.language === '') {
            next({name: 'results', query: {query: to.query.query, language: Store.state.searchQuery.language}});
            return;
        }
    }
    if (to.path) {
        NProgress.start();
    }
    next();
});

router.afterEach(() => {
    NProgress.done();
});

export default router;
