<script>
    import {Bar, mixins} from 'vue-chartjs'

    export default {
        extends: Bar,
        name: "SimpleGraph",
        mixins: [mixins.reactiveProp],
        props: ['options'],
        mounted() {
            this.addPlugin({ // Following Code sets the Pro/Contra Label on the y-axis
                afterDraw: chart => {
                    let ctx = chart.chart.ctx;
                    ctx.save();
                    let xAxis = chart.scales["x-axis-0"];
                    let yAxis = chart.scales["y-axis-0"];
                    ctx.fillStyle = "rgba(0, 0, 0, 0.8)";
                    ctx.textAlign = "center";
                    let fontSize = 12;
                    ctx.font = fontSize + "px Arial";
                    ctx.rotate(-Math.PI / 2);
                    let yZero = yAxis.getPixelForValue(0);
                    ctx.fillText("Pro", yZero / -2, fontSize);
                    ctx.fillText("Contra", (yAxis.bottom + yZero) / -2, fontSize);
                    ctx.restore();
                }
            })
            this.renderChart(this.chartData, this.options)
        },
        watch: {
            options: function (newVal, oldVal) {
                // Min/Max value in options can change dynamically, so we have to reload the chart manually, because
                // "options" of ChartJS isn't responsive right now
                this.renderChart(this.chartData, newVal)
            }
        }
    }
</script>

<style scoped>

</style>