<template>
    <div class="toggle mb-2">
        <span class="label pro">PRO</span>
        <div
                class="checkbox-toggle"
                role="checkbox"
                @keydown="toggle"
                @click.stop="toggle"
                tabindex="0"
                :aria-checked="toggled"
        >
            <div
                    class="checkbox-slide"
                    :class="classes"
            >
                <div
                        class="checkbox-switch"
                        :class="classes"
                        ref="checkboxSwitch"
                ></div>
            </div>
        </div>
        <span class="label contra">CON</span>
    </div>
</template>

<script>
    export default {
        name: "CheckboxToggle", computed: {
            classes: function() {
                return {
                    checked: this.toggled,
                    unchecked: !this.toggled,
                    disabled: this.disabled
                };
            },

            label: function() {
                return this.toggled && this.showLabels
                    ? this.labelChecked
                    : this.labelUnchecked;
            }
        },

        data() {
            return {
                toggled: this.value,
                touchStarted: null
            };
        },

        methods: {
            toggle: function(e) {
                if (this.disabled || e.keyCode === 9) { // not if disabled or tab is pressed
                    e.stop();
                }

                this.toggled = !this.toggled;

                this.$emit("input", this.toggled);
            },
            touchStart() {
                this.touchStarted = new Date();
                this.$refs.checkboxSwitch.classList.add('moving');
            },
            move(e) {
                let x = Math.min(26, Math.max(0, e.touches[0].clientX - e.target.offsetLeft));
                this.$refs.checkboxSwitch.style.transform = `translateX(${x}px)`;
                //this.$refs.checkboxSwitch.style.transition = 'none';
            },
            set(e) {
                //this.$refs.checkboxSwitch.style.transition = '';

                let diff = new Date() - this.touchStarted;
                if (diff > 100) {
                    var x = Math.min(26, Math.max(0, e.changedTouches[0].clientX - e.target.offsetLeft));
                    this.toggled = x > 13;

                } else {
                    this.toggled = !this.toggled;
                }
                setTimeout(() => {
                    this.$refs.checkboxSwitch.style.transform = '';
                    this.$refs.checkboxSwitch.classList.remove('moving');
                });
                this.$emit("input", this.toggled);
            }
        },

        props: {
            disabled: {
                type: Boolean,
                default: false
            },

            value: {
                type: Boolean,
                default: ""
            },

            showLabels: {
                type: Boolean,
                default: false
            },

            labelChecked: {
                type: String,
                default: ""
            },

            labelUnchecked: {
                type: String,
                default: ""
            }
        },
    }
</script>

<style scoped lang="scss">
    @import "@/scss/custom-bootstrap";
@import "~bootstrap/scss/bootstrap";
    $slideWidth: 54px;
    $borderRadius: $slideWidth;

    $switchWidth: $slideWidth / 1.8;
    $switchHeight: $slideWidth / 1.8;

    $distance: 25px;

    $switchColor: $white;
    $sliderColorActive: $ulb-grey-green;
    $sliderColorInactive: $green;

    $transitionTime: 350ms;

    .toggle {
        display: flex;
        align-items: center;
        user-select: none;
    }

    .label {
        font-size: 13px;
        padding: 0 4px;
        display: inline-block;
        height: $switchHeight;
        line-height: $switchHeight;
        user-select: none;
    }

    .checkbox-toggle {
        outline: none;
        width: $slideWidth;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        margin: 0.5em;
        display: inline-block;
    }

    .checkbox-slide {
        width: $slideWidth;
        padding: 0;
        margin: 0;
        border-radius: $borderRadius;
        cursor: pointer;
        border: 0px solid $gray-light;
        box-shadow: inset 1px 1px 5px 0 rgba(0, 0, 0, .3);
    }

    .checkbox-switch {
        padding: 0;
        margin: 0;
        width: $switchWidth;
        height: $switchHeight;
        border-radius: $borderRadius;
        background: linear-gradient(135deg, #dedede 0%, #ffffff 50%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#cecece', endColorstr='#ffffff', GradientType=1);
        cursor: pointer;
        border: 1px solid #bbb;
        box-sizing: border-box;
        box-shadow: 1px 1px 5px 0 rgba(0, 0, 0, .3);
    }

    .checkbox-label {
        margin-left: 0.5em;
    }

    .checkbox-switch.checked:not(.moving) {
        transform: translateX($distance);
        transition: all $transitionTime;
    }

    .checkbox-switch.unchecked:not(.moving) {
        transition: all $transitionTime;
    }

    .checkbox-slide.checked {
        transition: all $transitionTime;
        background: $sliderColorActive;
    }

    .checkbox-slide.unchecked {
        transition: all $transitionTime;
        background: $sliderColorInactive;
    }

    .checkbox-switch.disabled {
        cursor: not-allowed;
        background: lighten($switchColor, 35%);
    }

    .checkbox-slide.disabled {
        cursor: not-allowd;
        background: lighten($sliderColorInactive, 5%);
    }
</style>
