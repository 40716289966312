<template>
    <div>
        <section class="bg-light-blue" id="search-form-large">
            <search-form :query="query" :loading="loading"></search-form>
        </section>
        <section>
            <div class="container intro-text">
                <p>
                    A<span class="dark-blue fat"><a href="https://www.argumentext.de" target="_blank">rgumenText</a></span> allows to search for natural-language arguments in large document collections. Neural networks find and summarize the pros and cons of your topic in real time.
                  As document collections, TUprints, MDPI and Springer Open Access are searched by default. Explore up- and downsides of state-of-the-art research by searching for topics such as "<b-link to="/search/?query=electric cars" @click="setSearch('electric cars')">electric cars</b-link>" or "<b-link to="/search/?query=large language models"  @click="setSearch('large language models')">large language models</b-link>".
                  You can use quotes to search for exact phrases in documents.
                </p>
                <p>
                    <span class="dark-blue fat">
                        <a href="https://www.springeropen.com" target="_blank">Springer Open Access</a>
                    </span> provides metadata and full-text content where available for more than 649,000 online documents from Springer Nature, including BMC and SpringerOpen journals.
                </p>
                <p>
                    <span class="dark-blue fat">
                        <a href="https://tuprints.ulb.tu-darmstadt.de" target="_blank">TUprints</a>
                    </span> contains, amongst others, theses, articles, and books that were published on the TU Darmstadt's Open Access repository. 
                </p>
                <p>
                    <span class="dark-blue fat">
                        <a href="https://www.mdpi.com" target="_blank">MDPI</a>
                    </span> is a publisher of peer-reviewed, open access journals that span a wide range of scientific disciplines.
                </p>
                
                <p>To learn more about the technology behind this, see <b-link to="/background">here</b-link>.</p>
                <p><a href="https://tu-darmstadt.cloud.panopto.eu/Panopto/Pages/Viewer.aspx?id=d91e0a4d-57c7-4da3-842d-b171011d2533" target="_blank">This link</a> points to an online lecture which discusses the background of the project in depth. Lecture 3 includes a demo of the tool.</p>
            </div>
        </section>
        <section>
            <div class="container intro-text">
                We are happy about feedback - just <a href="mailto:ulb-da@summetix.com">email us</a>!
            </div>
        </section>
    </div>
</template>

<script>
    // Home.vue
    import SearchForm from "@/components/partials/SearchForm.vue";
    import SearchResult from "@/components/SearchResult.vue";

    export default {
        data() {
            return {
                query: "",
                loading: false
            }
        },
        methods: {
            setSearch(q) {
                this.query = q
                this.loading = true
            }
        },
        components: {
            SearchForm,
            SearchResult
        }
    }
</script>

<style lang="scss" scoped>
@import "@/scss/custom-bootstrap";
@import "~bootstrap/scss/bootstrap";
    .bg-light-blue {
        border-bottom: 2px solid #FFCA19;
        @extend .px-2;
    }

    .intro-text {
        margin-top: 80px;
        margin-bottom: 50px;
        max-width: 850px;
        color: $dark-green;
        font-weight: 300;
        line-height: 1.6em;
        @include media-breakpoint-up(lg) {
            font-size: 20px;
        }

        a, .fat {
            text-decoration: none;
            font-weight: 600;
        }

        a:hover {
            color: $yellow !important;
        }

        .dark-blue {
            color: $dark-green;
        }

        p:first-child:first-letter {
            color: $yellow;
            float: left;
            font-size: 112px;
            line-height: 80px;
            padding-top: 9px;
            padding-right: 8px;
            padding-left: 0;
            font-weight: 400;
        }
    }

    i {
        margin-right: 10px;
        margin-left: 5px;
    }

</style>
