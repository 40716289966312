<template>
  <div class="container">
    <select :value="language" class="choose-language" @change="changeLanguage">
      <option value="en">&#127468;&#127463; EN</option>
      <option value="de">&#127465;&#127466; DE</option>
    </select>

    <div v-if="language === 'en'">
      <h4 class="title">Background:</h4>

      <p>
        The ULB argument search system is driven by the ArgumenText technology
        which automatically identifies pro and con arguments in large text
        sources. For the purpose of supporting scientific literature research,
        we currently search meaningful statements in ULB's own
        <a href="https://tuprints.ulb.tu-darmstadt.de" target="_blank"
          >TUprints</a
        >
        collection as well as the
        <a href="https://www.mdpi.com/" target="_blank">MDPI</a> and
        <a href="https://www.springeropen.com" target="_blank"
          >Springer Open Access</a
        >
        collections.
      </p>
      <p>
        The technology has been developed over many years of research at
        <a
          href="https://www.informatik.tu-darmstadt.de/ukp/ukp_home/index.en.jsp"
          target="_blank"
          >TU Darmstadt's UKP Lab</a
        >
        and subsequently been converted into the summetix GmbH spin-off, founded
        in March 2021. The core components of the ULB argument search system are
        the document retrieval component (identifying relevant text snippets)
        and a multilingual argument detection model (identifying pro and con
        arguments in relevant text snippets). The argument detection model is a
        statistical model trained on thousands of arguments from many sources
        and on a large variety of topics and reaches a performance of 0.64 F1
        (macro-average) for the multilingual version (EN and DE) on the
        challenging sentential argument detection benchmark from Stab et al.
        (2018)
        <a v-scroll-to="'#ref1'">[1]</a>.
      </p>
      <p>Process chart below shows the steps from search to result:</p>
      <div>
        <img
          class="timeline-image"
          src="@/assets/images/screen_search.png"
          width="400px"
        />
        <Timeline :data="timelineDataEN" />
        <img
          class="timeline-image"
          src="@/assets/images/screen_results.png"
          width="500px"
        />
      </div>
      <br />
      <p>
        The user's query is used to search the document sources
        (TUprints, MDPI and Springer Open Access collections) in realtime. The most
        relevant documents are subsequently searched for arguments on
        sentence-level, i.e. each sentence is automatically classified by the
        argument detection model. The model drops sentences which are not
        identified as arguments and keeps those which are deemed to have
        supporting or opposing stances w.r.t. the user's query. Finally, the
        identified arguments are ranked by their relevance with regard to the
        search query.
      </p>
      <br />
      <h4 class="title">Further reading:</h4>
      <ul>
        <li>
          A detailed description of the underlying ArgumenText system:
          Daxenberger et al. (2020) <a v-scroll-to="'#ref2'">[2]</a>
        </li>
        <li>
          Background reading on the statistical model driving the argument
          identification component: Reimers et al. (2019)
          <a v-scroll-to="'#ref3'">[3]</a>
        </li>
        <li>
          Basics on Argument Mining and Sentential Argument Detection: Stab et
          al. (2018) <a v-scroll-to="'#ref1'">[1]</a>
        </li>
      </ul>
      <br />
      <h4 class="title">References:</h4>
      <ol>
        <li>
          <a
            id="ref1"
            name="ref1"
            href="http://aclweb.org/anthology/D18-1402"
            target="_blank"
            >http://aclweb.org/anthology/D18-1402</a
          >
        </li>
        <li>
          <a
            id="ref2"
            name="ref2"
            href="https://link.springer.com/article/10.1007%2Fs13222-020-00347-7"
            target="_blank"
            >https://link.springer.com/article/10.1007%2Fs13222-020-00347-7</a
          >
        </li>
        <li>
          <a
            id="ref3"
            name="ref3"
            href="https://www.aclweb.org/anthology/P19-1054"
            target="_blank"
            >https://www.aclweb.org/anthology/P19-1054</a
          >
        </li>
      </ol>
    </div>
    <div v-else>
      <h4 class="title">Hintergrund:</h4>
      <p>
        Die ULB Argument Search baut auf die ArgumenText Technologie auf, welche
        automatisch Pro- und Kontra-Agumente in großen Textbeständen identifiziert.
        Um die wissenschaftliche Literaturrecherche zu unterstützen, durchsuchen wir
        nach aussagekräftigen Statements in der ULB-eigenen Sammlung
         <a href="https://tuprints.ulb.tu-darmstadt.de" target="_blank"
          >TUprints</a
        >
        wie auch der
        <a href="https://www.mdpi.com/"  target="_blank">MDPI</a> und
        <a href="https://www.springeropen.com" target="_blank"
          >Springer Open Access</a
        >
        Sammlungen.
      </p>
      <p>
      Die Technologie wurde entwickelt in jahrelanger Forschung am
        <a
          href="https://www.informatik.tu-darmstadt.de/ukp/ukp_home/index.en.jsp"
          target="_blank"
          >UKP Lab</a
        > der TU Darmstadt und anschließend im Rahmen einer Ausgründung an die
        summetix GmbH, gegründet im März 2021, transferiert. Die Kernkomponenten
        der ULB Argument Search sind ein Dokument Retrieval System, welches passende
        Dokumente oder Dokumentteile identifiziert, sowie ein mehrsprachiges
        Argument Detection Modell, welches Pro- und Kontra-Argumente in den
        relevanten Dokumenten erkennt.
        Letzeres ist ein statistisches Modell das auf tausenden von Argumenten
        aus vielen Quellen und zu vielen Themen trainiert wurde. Es erreicht eine
        Genauigkeit von 0.64 F1 (macro-average) für die mehrsprachige Version (EN und DE)
        des herausfordernden Sentential Argument Detection Benchmark aus Stab et al.
        (2018)
        <a v-scroll-to="'#ref1'">[1]</a>.
      </p>
      <p>Der Gesamtprozess läuft folgendermaßen:</p>
      <div>
        <img
          class="timeline-image"
          src="@/assets/images/screen_search.png"
          width="400px"
        />
        <Timeline :data="timelineDataDE" />
        <img
          class="timeline-image"
          src="@/assets/images/screen_results.png"
          width="500px"
        />
      </div>
      <br />
      <p>
        Mit der Suchanfrage der Benutzenden werden die Dokumentsammlungen
        (TUprints, MDPI und Springer Open Access) in Echtzeit durchsucht.
        In den relevantesten Dokumenten sucht das Modell dann auf Satzebene nach
        Argumenten. Sätze, die nicht als Argument erkannt werden, werden nicht angezeigt,
        die anderen Sätze (Pro- und Kontra-Argumente) werden nach ihrer Relevanz zum Suchbegriff
        gerankt und angezeigt.
      </p>
      <br />
      <h4 class="title">Weiteres Material:</h4>
      <ul>
        <li>
          Detaillierte Bechreibung des ArgumenText Systems:
          Daxenberger et al. (2020) <a v-scroll-to="'#ref2'">[2]</a>
        </li>
        <li>
          Zum Hintergrund des statitischen Modells zur Argumenterkennung: Reimers et al. (2019)
          <a v-scroll-to="'#ref3'">[3]</a>
        </li>
        <li>
          Grundlagen zu Argument Mining und Sentential Argument Detection: Stab et
          al. (2018) <a v-scroll-to="'#ref1'">[1]</a>
        </li>
      </ul>
      <br />
      <h4 class="title">Literatur:</h4>
      <ol>
        <li>
          <a
            id="ref1"
            name="ref1"
            href="http://aclweb.org/anthology/D18-1402"
            target="_blank"
            >http://aclweb.org/anthology/D18-1402</a
          >
        </li>
        <li>
          <a
            id="ref2"
            name="ref2"
            href="https://link.springer.com/article/10.1007%2Fs13222-020-00347-7"
            target="_blank"
            >https://link.springer.com/article/10.1007%2Fs13222-020-00347-7</a
          >
        </li>
        <li>
          <a
            id="ref3"
            name="ref3"
            href="https://www.aclweb.org/anthology/P19-1054"
            target="_blank"
            >https://www.aclweb.org/anthology/P19-1054</a
          >
        </li>
      </ol>
    </div>
  </div>
</template>

<script>
import Timeline from "@/components/partials/Timeline.vue";
export default {
  name: "Background",
  data() {
    return {
      timelineDataDE: [
        {
          text: "Benutzer*in gibt Suchbegriff ein und wählt Themenbereich (optional)",
        },
        {
          text: "Dokument Retrieval basierend auf der Eingabe",
        },
        {
          text: "Volltext-Abruf der relevanstesten Dokumente",
        },
        {
          text: "Argument Detection auf Satzebene",
        },
        {
          text: "Stance Detection (Pro/Kontra)",
        },
        {
          text: "Argument Ranking",
        },
        {
          text: "Die erkannten Argument werden angezeigt",
        },
      ],
      timelineDataEN: [
        {
          text: "User enters search query and subjects (optional)",
        },
        {
          text: "Document retrieval based on user query",
        },
        {
          text: "Full-text retrieval of most relevant documents",
        },
        {
          text: "Sentence-level argument detection",
        },
        {
          text: "Stance detection",
        },
        {
          text: "Argument ranking",
        },
        {
          text: "Identified arguments are displayed",
        },
      ],
      language: "en"
    }
  },
  methods: {
      changeLanguage(event) {
          this.language = event.target.value
      }
  },
  components: {
    Timeline,
  },
};
</script>

<style scoped lang="scss">
    @import "@/scss/custom-bootstrap";
@import "~bootstrap/scss/bootstrap";
.container {
  width: 90%;
}

.title {
  color: $ulb-grey-green;
  font-size: 16pt;
  font-weight: 600;
  margin-bottom: 20px;
}
a {
  cursor: pointer;
}
ol {
  li::marker {
    content: "[" counter(list-item, decimal) "] ";
    text-indent: 20px;
  }
  li::before {
    margin: 20px;
  }
}
.timeline-image {
  margin: 0 50%;
  transform: translateX(-50%);
  border: 1px solid #ddd;
}
.choose-language {
  float: right;
}
</style>